import { Injectable } from '@angular/core';

import { ModuleMetadata } from './module-metadata';

import { AbstractModuleMetadataService } from '@fiyu/core';

@Injectable()
export class WorkspaceModuleMetadataService extends AbstractModuleMetadataService {
    constructor() {
        super(ModuleMetadata.getInstance());
    }
}
