import { ValidationError } from '@fiyu/core';

const validationErrors: ValidationError[] = [
    {
        error: {
            key: 'TSH_V_GLOBAL_NOT_FOUND',
        },
        message: 'Data not found.', // translate this
    },
    {
        error: {
            key: 'TSH_V_GLOBAL_INVALID_PARAMETER',
        },
        message: 'Input parameter is not valid.', // translate this
    },
    {
        error: {
            key: 'TSH_V_GLOBAL_NOT_NULL',
        },
        message: 'Object must not be null.', // translate this
    },
    {
        error: {
            key: 'TSH_V_GLOBAL_NOT_AUTHORIZED',
        },
        message: 'User not authorized to access requested method.', // translate this
    },
    {
        error: {
            key: 'TSH_V_GLOBAL_LOCKED_DAY',
        },
        message: 'Can not be entered because the selected day is locked.', // translate this
    },
    {
        error: {
            key: 'TSH_V_ABSENT_TIME_EDITING_PAST_TIME',
        },
        message: 'Can not add or edit absent time for past, please contact Personnel services.', // translate this
    },
    {
        error: {
            key: 'TSH_V_ABSENT_TIME_EXCEEDING_MANAGE_WORKLOG_IN_PAST',
        },
        message: 'Can not edit absent time because it exceedes MANAGE_WORKLOG_IN_PAST setting.', // replace MANAGE_WORKLOG_IN_PAST with value from the TSH settings
    },
    {
        error: {
            key: 'TSH_V_ABSENT_TIME_OVERLAPPING_WITH_ABSENT_TIME',
        },
        message: 'Absent time entry is overlapping with existing absent time entry. Please check the dates', // translate this
    },
    {
        error: {
            key: 'TSH_V_ABSENT_TIME_OVERLAPPING_WITH_WORK_TIME',
        },
        message: 'Absent time is overlapping with existing worktime entry. Please check the dates ', // translate this
    },
    {
        error: {
            key: 'TSH_V_BREAK_TIME_OVERLAPPING_WITH_BREAK_TIME',
        },
        message: 'Break time entry is overlapping with existing break time entry.', // translate this
    },
    {
        error: {
            key: 'TSH_V_BREAK_TIME_OVERLAPPING_WITH_ABSENT_TIME',
        },
        message: 'Break time entry is overlapping with existing absent time entry.', // translate this
    },
    {
        error: {
            key: 'TSH_V_BREAK_TIME_LESS_THAN_MIN_HOURS',
        },
        message: 'Break time is not allowed for work day with less than minimum working hours.', // translate this
    },
    {
        error: {
            key: 'TSH_V_CLIENT_FOREIGN_KEY',
        },
        message: 'Can not delete client entry as there is an existing connection to other entities.', // translate this
    },
    {
        error: {
            key: 'TSH_V_CONTRACT_FOREIGN_KEY',
        },
        message: 'Can not delete contract entry as there is an existing connection to other entities.', // translate this
    },
    {
        error: {
            key: 'TSH_V_ENGAGEMENT_FOREIGN_KEY',
        },
        message: 'Can not delete engagement entry as there is an existing connection to other entities.', // translate this
    },
    {
        error: {
            key: 'TSH_V_ENGAGEMENT_INACTIVE_PROJECT',
        },
        message:
            'Project is not active on that period of time. Please extend project duration or choose another engagement period.', // translate this
    },
    {
        error: {
            key: 'TSH_INVALID_DAY_BREAK_TIME_MISSING',
        },
        message: 'Employee did not enter a break time.', // translate this
    },
    {
        error: {
            key: 'TSH_INVALID_DAY_BREAK_TIME_TOO_LONG',
        },
        message: 'Break time longer than BREAK_TIME_DURATION min.', // replace BREAK_TIME_DURATION with value from the TSH settings
    },
    {
        error: {
            key: 'TSH_INVALID_DAY_WORK_TIME_TOO_SHORT',
        },
        message: 'The number of working hours is less than min number of working hours per day.', // translate this
    },
    {
        error: {
            key: 'TSH_INVALID_DAY_WORK_TIME_MISSING',
        },
        message: 'Employee did not enter working hours.', // translate this
    },
    {
        error: {
            key: 'TSH_INVALID_DAY_BREAK_TIME_TOO_SHORT',
        },
        message: 'Break time duration is shorter than BREAK_TIME_DURATION minutes.', // replace BREAK_TIME_DURATION with value from the TSH settings
    },
    {
        error: {
            key: 'TSH_V_OVERTIME_NOT_OVERTIME_TYPE',
        },
        message: 'Worktime entry is not of overtime type.', // translate this
    },
    {
        error: {
            key: 'TSH_V_OVERTIME_OVERLAPING_WITH_WORK_TIME',
        },
        message: 'Overtime entry is overlapping with existing worktime entry.',
    },
    {
        error: {
            key: 'TSH_V_OVERTIME_OVERLAPING_WITH_ABSENT_TIME',
        },
        message: 'Overtime entry is overlapping with existing absent time entry.',
    },
    {
        error: {
            key: 'TSH_V_PROJECT_MISSING_MINIMAL_HOURS_TO_PAY',
        },
        message: 'If clientPaysBreakTime is selected, minimalHoursToPay filed is required.', // translate this
    },
    {
        error: {
            key: 'TSH_V_PROJECT_FOREIGN_KEY',
        },
        message: 'Can not delete project entry as there is an existing connection to other entities.', // translate this
    },
    {
        error: {
            key: 'TSH_V_SETTINGS_NOT_DEFINED',
        },
        message: 'Setting is not defined.', // translate this
    },
    {
        error: {
            key: 'TSH_V_TASK_PROJECT_FINISHED',
        },
        message: 'Can not create task for a finished project.', // translate this
    },
    {
        error: {
            key: 'TSH_V_TASK_FOREIGN_KEY',
        },
        message: 'Can not delete task entry as there is an existing connection to other entities.', // translate this
    },
    {
        error: {
            key: 'TSH_V_TASK_NOT_EDITABLE',
        },
        message: 'Can not edit task entry for this project as this project tasks are not editable.', // translate this
    },
    {
        error: {
            key: 'TSH_V_UNLOCK_REQUEST_LOCKED_DAY',
        },
        message:
            'Can not create or edit entry for selected day. Please send unlock request for selected day or contact Personnel services.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKLOG_PREVIOUS_MONTH',
        },
        message: 'Can not edit worklog that is not in this month.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKLOG_EXCEEDING_MANAGE_WORKLOG_IN_PAST',
        },
        message: 'Can not manage the entry because it exceeds MANAGE_WORKLOG_IN_PAST setting.', // replace MANAGE_WORKLOG_IN_PAST with value from the TSH settings
    },
    {
        error: {
            key: 'TSH_V_WORKLOG_OTHER_USER',
        },
        message: 'Can not edit worklog for other users.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKLOG_NOT_SUPERIOR',
        },
        message: 'Can not edit worklog for user that is not your subordinate.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKLOG_HOLIDAY',
        },
        message: 'National holiday - it isn’t possible to add Regular work',
    },
    {
        error: {
            key: 'TSH_V_WORKTIME_OVERLAPING_WITH_WORK_TIME',
        },
        message: 'Worktime entry is overlapping with existing worktime entry for selected time.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKTIME_OVERLAPING_WITH_ABSENT_TIME',
        },
        message: 'Worktime entry is overlapping with existing absent time entry. Please check the date.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKTIME_TASK_CREATED_AND_CHOSEN',
        },
        message: 'Fields taskId and newTask exclude one another. Send only one of these values in the object.', // translate this
    },

    {
        error: {
            key: 'TSH_V_WORKTIME_MANAGE_WORKLOG_IN_FUTURE',
        },
        message: 'Can not manage worktime entry for future date.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKTIME_INACTIVE_ENGAGEMENT',
        },
        message: 'Can not create worktime entry for inactive engagement.', // translate this
    },
    {
        error: {
            key: 'TSH_V_WORKTIME_INACTIVE_ENGAGEMENT',
        },
        message: 'Can not create worktime entry for inactive engagement.', // translate this
    },
    {
        error: {
            key: 'TSH_V_PASSIVEOVERTIME_OVERLAPING_WITH_PASSIVEOVERTIME',
        },
        message: 'Passive overtime entry is overlapping with existing Passive overtime entry.', // translate this
    },
    {
        error: {
            key: 'TSH_V_ENGAGEMENT_INACTIVE_CONTRACT',
        },
        message: 'Engagement period must be within or exact as selected Contract Period.',
    },
    {
        error: {
            key: 'TSH_V_ENGAGEMENT_CONTAINS_WORK_TIME',
        },
        message: 'Engagement period cannot be deleted because it contains logged Work Time.',
    },
    {
        error: {
            key: 'TSH_V_CONTRACT_UNIQUE_CONTRACT_NUMBER',
        },
        message: 'Can not create contract as there is a contract with the same contract number.',
    },
    {
        error: {
            key: 'TSH_V_TASK_WITH_SAME_NAME_EXISTS',
        },
        message: 'Can not create task. Task with the same name already exists for selected project.',
    },
    {
        error: {
            key: 'TSH_V_PROJECT_WITH_SAME_NAME_EXISTS',
        },
        message: 'Cannot create project. Project with the same name already exists.',
    },
    {
        error: {
            key: 'TSH_V_PROJECT_WITH_SAME_INTERNAL_CODE_EXISTS',
        },
        message: 'Can not create project. Project with the same internal code already exists.',
    },
    {
        error: {
            key: 'TSH_V_CLIENT_WITH_SAME_NAME_EXISTS',
        },
        message: 'Can not create client. Client with the same name already exists.',
    },
    {
        error: {
            key: 'TSH_V_CLIENT_WITH_SAME_INTERNAL_CODE_EXISTS',
        },
        message: 'Can not create client. Client with the same internal code already exists.',
    },
    {
        error: {
            key: 'TSH_V_CLIENT_WITH_SAME_VAT_ID_EXISTS',
        },
        message: 'Can not create client. Client with the same VAT id already exists.',
    },
    {
        error: {
            key: 'TSH_V_WORKLOG_EXCEEDING_MANAGE_WORKLOG_IN_PAST',
        },
        message: 'Can not manage worklog entries older than 1 month from the beginning of the current period.',
    },
];

// Default export must be used for proper loading of errors in resolver
export default validationErrors;
