import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

_('timesheet.worktime');
_('timesheet');
_('timesheet.tasks');
_('timesheet.absent.time');
_('timesheet.worktime.summary');
_('timesheet.worktime.summaries');


export enum TranslateKeys {
  Timesheet = 'timesheet',
  Worktime = 'timesheet.worktime',
  Tasks = 'timesheet.tasks',
  AbsentTime = 'timesheet.absent.time',
  WorktimeSummary = 'timesheet.worktime.summary',
  WorktimeSummaries = 'timesheet.worktime.summarie',
}
