import { Injectable } from '@angular/core';
import { AbstractSettingsService } from '@fiyu/ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModuleMetadata } from '../module-metadata';
import { TimesheetModuleSettings } from '../timesheet-module-settings';

@Injectable({
    providedIn: 'root',
})
export class TimesheetSettingsService extends AbstractSettingsService {
    private settingChangedSubject = new BehaviorSubject<TimesheetModuleSettings>(new TimesheetModuleSettings());
    private settingChanged$ = this.settingChangedSubject.asObservable();

    constructor() {
        super('/settings', ModuleMetadata.getInstance());
    }

    changeSettings(settings: TimesheetModuleSettings) {
        this.settingChangedSubject.next(settings);
    }

    settingsChanged(): Observable<TimesheetModuleSettings> {
        return this.settingChanged$;
    }
}
