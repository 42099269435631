import { Injectable, inject } from '@angular/core';
import { ModuleMetadata } from './module-metadata';
import { AbstractModuleMetadataService, ErrorLoadingService, VersionInfoService } from '@fiyu/core';
import validationErrors from './errors/validation-errors';
import { TimesheetSettingsService } from './settings/timesheet-settings.service';

@Injectable({
    providedIn: 'root',
})
export class TimesheetModuleMetadataService extends AbstractModuleMetadataService {
    private readonly errorLoadingService: ErrorLoadingService = inject(ErrorLoadingService);
    private readonly timeSheetSettings: TimesheetSettingsService = inject(TimesheetSettingsService);
    constructor() {
        super(ModuleMetadata.getInstance());
        this.errorLoadingService.loadValidationErrorsForModule(validationErrors);
    }
}
