import { AbstractModuleMetadata } from '@fiyu/core';
import { Operations, Permissions } from '@fiyu/api';
import { ModulePermissions } from './module-permissions';

/**
 * Class with metadata for module
 */
export class ModuleMetadata extends AbstractModuleMetadata {
    /**
     * Is module local module only, server doesn't know about it
     */
    public isCore = false;
    /**
     * Module name
     */
    public moduleName = 'Timesheet';

    /**
     * Short name
     */
    public moduleNameShort = 'TS';

    /**
     * Module description
     */
    public moduleDescription = 'Work log and time management application';

    /**
     *  Module prefix used in navigation and url
     */
    public modulePrefix = 'timesheet';
    /**
     *  Module prefix used in navigation and url
     */
    public moduleLogoColor = '#009688';

    /**
     *  Module darker color
     */
    public moduleLogoDarkerColor = '#6B1059';

    /**
     * Module name used for accessing user module permissions
     */
    public moduleAlias = 'TSH';

     /**
     * Module icon
     */
    public moduleIcon = 'pi pi-calendar-times';

    /**
     * Static class with module persmissions
     */
    public modulePermissions = ModulePermissions;

    /**
     * Sidebar permissions
     */
    calendarPermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_BREAK_VIEW,
            ModulePermissions.TSH_ABSENT_VIEW,
            ModulePermissions.TSH_WORKLOG_VIEW,
        ],
        operation: Operations.AND,
    };
    tasksPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_TASK_VIEW, ModulePermissions.TSH_TASK_CREATE],
        operation: Operations.AND,
    };
    workLogPermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_BREAK_VIEW,
            ModulePermissions.TSH_ABSENT_VIEW,
            ModulePermissions.TSH_WORKLOG_VIEW,
        ],
        operation: Operations.OR,
    };

    administrationWorkLogPermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_BREAK_MANAGE_ALL,
            ModulePermissions.TSH_BREAK_MANAGE_SUBORDINATE,
            ModulePermissions.TSH_ABSENT_MANAGE_ALL,
            ModulePermissions.TSH_ABSENT_MANAGE_SUBORDINATE,
            ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
            ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
            ModulePermissions.TSH_OVERTIME_MANAGE_ALL,
            ModulePermissions.TSH_OVERTIME_MANAGE_SUBORDINATE,
        ],
        operation: Operations.OR,
    };
    worktimePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_WORKLOG_VIEW],
        operation: Operations.AND,
    };
    administrationWorktimePermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
            ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
        ],
        operation: Operations.OR,
    };

    absentTimePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_ABSENT_VIEW],
        operation: Operations.AND,
    };
    administrationAbsentTimePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_ABSENT_MANAGE_ALL, ModulePermissions.TSH_ABSENT_MANAGE_SUBORDINATE],
        operation: Operations.OR,
    };

    breakTmePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_BREAK_VIEW],
        operation: Operations.AND,
    };
    administrationBreakTmePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_BREAK_MANAGE_ALL, ModulePermissions.TSH_BREAK_MANAGE_SUBORDINATE],
        operation: Operations.OR,
    };

    overtimePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
        operation: Operations.AND,
    };

    incompletePermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_REPORT_INCOMPLETE_DAYS_VIEW],
        operation: Operations.AND,
    };

    administrationOvertimePermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_OVERTIME_MANAGE_ALL,
            ModulePermissions.TSH_OVERTIME_MANAGE_SUBORDINATE,
        ],
        operation: Operations.OR,
    };

    projectsPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_PROJECT_VIEW],
        operation: Operations.AND,
    };

    contractsPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_CONTRACT_VIEW],
        operation: Operations.AND,
    };

    clientsPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_CLIENT_VIEW],
        operation: Operations.AND,
    };

    engagementsPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_ENGAGEMENT_VIEW],
        operation: Operations.AND,
    };

    reportPermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_REPORT_EMPLOYEE_HOURS_VIEW,
            ModulePermissions.TSH_REPORT_EMPLOYEE_CLIENT_VIEW,
            ModulePermissions.TSH_REPORT_PANTHEON_VIEW,
            ModulePermissions.TSH_REPORT_BENCH_VIEW,
        ],
        operation: Operations.OR,
    };

    reportEmployeeHoursPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_REPORT_EMPLOYEE_HOURS_VIEW],
        operation: Operations.AND,
    };

    reportEmployeeClientPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_REPORT_EMPLOYEE_CLIENT_VIEW],
        operation: Operations.AND,
    };

    reportEmployeeTaskPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_REPORT_EMPLOYEE_CLIENT_VIEW],
        operation: Operations.AND,
    };

    reportPantheonPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_REPORT_PANTHEON_VIEW],
        operation: Operations.AND,
    };

    reportBenchPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_REPORT_BENCH_VIEW],
        operation: Operations.AND,
    };

    unlockRequestsPermissions: Permissions = {
        requiredPermissions: [
            ModulePermissions.TSH_UNLOCK_VIEW_ALL,
            ModulePermissions.TSH_UNLOCK_MENAGE_ALL,
            ModulePermissions.TSH_UNLOCK_MANAGE_SUBORDINATE,
        ],
        operation: Operations.OR,
    };

    settingsPermissions: Permissions = {
        requiredPermissions: [ModulePermissions.TSH_SETTINGS_EDIT, ModulePermissions.TSH_SETTINGS_CREATE],
        operation: Operations.OR,
    };
    /**
     * Module sidebar navigation
     */
    public menuItems = [
        {
            label: 'Timesheet',

            items: [
                {
                    label: 'Calendar',
                    icon: 'pi pi-calendar-times',
                    routerLink: '/timesheet/calendar',
                    permissions: this.calendarPermissions,
                },
                {
                    label: 'Work log',
                    icon: 'pi pi-fw pi-sitemap',
                    permissions: this.workLogPermissions,
                    items: [
                        {
                            label: 'Work time',
                            icon: 'pi pi-fw pi-clock',
                            routerLink: '/timesheet/worktimes',
                            permissions: this.worktimePermissions,
                        },
                        {
                            label: 'Break time',
                            icon: 'pi pi-fw pi-pause',
                            routerLink: '/timesheet/break-times',
                            permissions: this.breakTmePermissions,
                            visible: true,
                        },
                        {
                            label: 'Absent time',
                            icon: 'pi pi-fw pi-home',
                            routerLink: '/timesheet/absent-times',
                            permissions: this.absentTimePermissions,
                        },
                        {
                            label: 'Overtimes',
                            icon: 'pi pi-fw pi-history',
                            permissions: this.overtimePermissions,
                            items: [
                                {
                                    label: 'Overtime',
                                    icon: 'pi pi-fw pi-circle-fill',
                                    routerLink: '/timesheet/overtimes',
                                },
                                {
                                    label: 'Passive overtime',
                                    icon: 'pi pi-fw pi-circle',
                                    routerLink: '/timesheet/passive-overtimes',
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Administration',
                    icon: 'pi pi-fw pi-sitemap',
                    permissions: this.administrationWorkLogPermissions,
                    items: [
                        {
                            label: 'Work time',
                            icon: 'pi pi-fw pi-clock',
                            routerLink: '/timesheet/admin/worktimes',
                            permissions: this.administrationWorktimePermissions,
                        },
                        {
                            label: 'Break time',
                            icon: 'pi pi-fw pi-pause',
                            routerLink: '/timesheet/admin/break-times',
                            permissions: this.administrationBreakTmePermissions,
                            visible: true,
                        },
                        {
                            label: 'Absent time',
                            icon: 'pi pi-fw pi-home',
                            routerLink: '/timesheet/admin/absent-times',
                            permissions: this.administrationAbsentTimePermissions,
                        },
                        {
                            label: 'Overtimes',
                            icon: 'pi pi-fw pi-history',
                            routerLink: '/timesheet/admin/overtimes',
                            permissions: this.administrationOvertimePermissions,
                            items: [
                                {
                                    label: 'Overtime',
                                    icon: 'pi pi-fw pi-circle-fill',
                                    routerLink: '/timesheet/admin/overtimes',
                                },
                                {
                                    label: 'Passive overtime',
                                    icon: 'pi pi-fw pi-circle',
                                    routerLink: '/timesheet/admin/passive-overtimes',
                                },
                            ],
                        },
                        {
                            label: 'Incomplete days',
                            icon: 'pi pi-fw pi-user-minus',
                            routerLink: '/timesheet/admin/incomplete-days',
                            permissions: this.incompletePermissions,
                        },
                    ],
                },
                {
                    label: 'Tasks',
                    icon: 'pi pi-fw pi-pencil',
                    routerLink: '/timesheet/tasks',
                    permissions: this.tasksPermissions,
                },
                {
                    label: 'Contracts',
                    icon: 'pi pi-fw pi-file',
                    routerLink: '/timesheet/contracts',
                    permissions: this.contractsPermissions,
                },
                {
                    label: 'Projects',
                    icon: 'pi pi-fw pi-calendar-times',
                    routerLink: '/timesheet/projects',
                    permissions: this.projectsPermissions,
                },
                {
                    label: 'Clients',
                    icon: 'pi pi-fw pi-briefcase',
                    routerLink: '/timesheet/clients',
                    permissions: this.clientsPermissions,
                },
                {
                    label: 'Engagements',
                    icon: 'pi pi-fw pi pi-euro',
                    routerLink: '/timesheet/engagements',
                    permissions: this.engagementsPermissions,
                },
                {
                    label: 'Unlock requests',
                    routerLink: '/timesheet/unlock-requests',
                    icon: 'pi pi-fw pi-lock',
                    permissions: this.unlockRequestsPermissions,
                },
                {
                    label: 'Reports',
                    icon: 'pi pi-fw pi-book',
                    permissions: this.reportPermissions,
                    items: [
                        {
                            label: 'Employee hours report',
                            icon: 'pi pi-fw pi-clock',
                            routerLink: '/timesheet/reports/employee-hours',
                            permissions: this.reportEmployeeHoursPermissions,
                        },
                        {
                            label: 'Employee client report',
                            icon: 'pi pi-fw pi-user',
                            routerLink: '/timesheet/reports/employee-client',
                            permissions: this.reportEmployeeClientPermissions,
                        },
                        {
                            label: 'Employee task report',
                            icon: 'pi pi-fw pi-user',
                            routerLink: '/timesheet/reports/employee-task',
                            permissions: this.reportEmployeeTaskPermissions,
                        },
                        {
                            label: 'Pantheon report',
                            icon: 'pi pi-fw pi-file-excel',
                            routerLink: '/timesheet/reports/pantheon',
                            permissions: this.reportPantheonPermissions,
                        },
                        {
                            label: 'Bench report',
                            icon: 'pi pi-fw pi-user',
                            routerLink: '/timesheet/reports/bench',
                            permissions: this.reportBenchPermissions,
                        },
                    ],
                },
                {
                    label: 'Email',
                    routerLink: '/timesheet/email',
                    icon: 'pi pi-fw pi-inbox',
                    permissions: this.settingsPermissions,
                },
                {
                    label: 'Settings',
                    routerLink: '/timesheet/settings',
                    icon: 'pi pi-fw pi-cog',
                    permissions: this.settingsPermissions,
                },
                {
                    label: 'About',
                    routerLink: '/timesheet/about',
                    icon: 'pi pi-fw pi-info',
                },
            ],
        },
    ];
}
