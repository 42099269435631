export enum ModulePermissions {
    REPORTING_EDIT = 'REPORTING_EDIT',
    REPORTING_VIEW_CAMPAIGN_ALL = 'REPORTING_VIEW_CAMPAIGN_ALL',
    REPORTING_VIEW_CAMPAIGN_CLIENT = 'REPORTING_VIEW_CAMPAIGN_CLIENT',
    REPORTING_VIEW_DRIVER_REPORT = 'REPORTING_VIEW_DRIVER_REPORT',
    REPORTING_VIEW_CAMPAIGN_OVERVIEW = 'REPORTING_VIEW_CAMPAIGN_OVERVIEW',
    REPORTING_VIEW_CAMPAIGN_FLEET_ORGANIZATION = 'REPORTING_VIEW_CAMPAIGN_FLEET_ORGANIZATION',
    REPORTING_VIEW_CAMPAIGN_AGENCY = 'REPORTING_VIEW_CAMPAIGN_AGENCY',
    REPORTING_VIEW_CAMPAIGN_SUMMARY = 'REPORTING_VIEW_CAMPAIGN_SUMMARY',
    REPORTING_VIEW_CAMPAIGN_LIST = 'REPORTING_VIEW_CAMPAIGN_LIST',
    REPORTING_VIEW_CAMPAIGN_DRIVER = 'REPORTING_VIEW_CAMPAIGN_DRIVER',
    MDM_VIEW = 'MDM_VIEW',
    MDM_EDIT = 'MDM_EDIT',
    MDM_RIDE_VIEW = 'MDM_RIDE_VIEW',
    MDM_RIDE_EDIT = 'MDM_RIDE_EDIT',
    DAA_GEOLOCATION_VIEW = 'DAA_GEOLOCATION_VIEW',
    DAA_CAMPAIGN_MANAGE_ALL = 'DAA_CAMPAIGN_MANAGE_ALL',
    DAA_CAMPAIGN_MANAGE_MY = 'DAA_CAMPAIGN_MANAGE_MY',

    USER_VIEW = 'USER_VIEW',
    USER_EDIT = 'USER_EDIT',
    ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
    ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',
    ORGANIZATION_USER_VIEW = 'ORGANIZATION_USER_VIEW',
    ORGANIZATION_USER_EDIT = 'ORGANIZATION_USER_EDIT',
    ORGANIZATION_TYPE_VIEW = 'ORGANIZATION_TYPE_VIEW',
    ORGANIZATION_TYPE_EDIT = 'ORGANIZATION_TYPE_EDIT',
    ROLE_TEMPLATE_VIEW = 'ROLE_TEMPLATE_VIEW',
    ROLE_TEMPLATE_EDIT = 'ROLE_TEMPLATE_EDIT',
    APPLICATION_VIEW = 'APPLICATION_VIEW',
    APPLICATION_EDIT = 'APPLICATION_EDIT',
    DMM_RUN = 'DMM_RUN',
    DMM_UPLOAD = 'DMM_UPLOAD',
    DMM_EDIT = 'DMM_EDIT',
    DMM_DOWNLOAD = 'DMM_DOWNLOAD',
    DMM_PREVIEW = 'DMM_PREVIEW',
    DMM_DELETE = 'DMM_DELETE',
    NMM_RUN = 'NMM_RUN',
    NMM_SEND = 'NMM_SEND',
    TSH_VIEW = 'TSH_VIEW',
    PLATFORM_RUN = 'PLATFORM_RUN',
    USER_RUN = 'USER_RUN',
    ORGANIZATION_RUN = 'ORGANIZATION_RUN',
}
