import { inject } from '@angular/core';
import { ModulePrefixes, RequiredModulesConfig, SharedTranslateKeys } from '@fiyu/api';
import { ActivationGuard, AuthGuard, FiyuRoutes, PermissionsMatchGuard, loadScriptResolver } from '@fiyu/core';
import { ModulePermissions } from './workspace/module-permissions';
import { TranslateKeys } from '@getihub/timesheet';

const appChildRoutes: FiyuRoutes = [
    {
        path: ModulePrefixes.PLATFORM,
        title: SharedTranslateKeys.Platform,
        loadChildren: async () => (await import(`@fiyu/platform`)).PlatformRoutes,
        canMatch: [ActivationGuard, PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN],
    },
    {
        path: ModulePrefixes.USER,
        title: SharedTranslateKeys.Users,
        loadChildren: async () => (await import(`@fiyu/users`)).UsersRoutes,
        canMatch: [ActivationGuard, PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN],
    },
    {
        path: ModulePrefixes.ORGANIZATION,
        title: SharedTranslateKeys.Organizations,
        loadChildren: async () => (await import(`@fiyu/organizations`)).OrganizationsRoutes,
        canMatch: [ActivationGuard, PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN],
    },
    {
        path: ModulePrefixes.NOTIFICATIONS,
        title: SharedTranslateKeys.Notifications,
        loadChildren: async () => (await import('@fiyu/notifications')).NotificationsRoutes,
        canMatch: [ActivationGuard, PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.NOTIFICATIONS,
        requiredPermissions: [ModulePermissions.NMM_RUN],
    },
    {
        path: ModulePrefixes.DOCUMENTS,
        title: SharedTranslateKeys.Documents,
        loadChildren: async () => (await import('@fiyu/documents')).DocumentsRoutes,
        canMatch: [ActivationGuard, PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.DOCUMENTS,
        requiredPermissions: [ModulePermissions.DMM_RUN],
    },
    {
        path: 'timesheet',
        title: TranslateKeys.Timesheet,
        loadChildren: () => import(`@getihub/timesheet`).then((m) => m.TimesheetRoutes),
        canMatch: [ActivationGuard, PermissionsMatchGuard],
        requiredModule: 'tsh',
        requiredPermissions: [ModulePermissions.TSH_VIEW],
    },
    {
        path: '',
        title: SharedTranslateKeys.Workspace,
        loadComponent: async () => (await import('./workspace/workspace/workspace.component')).WorkspaceComponent,
    },
];

export const AppRoutes: FiyuRoutes = [
    {
        path: '',
        loadComponent: async () => (await import('./layout/main/app.main.component')).AppMainComponent,
        children: appChildRoutes,
        canActivate: [AuthGuard],
        data: { scriptName: 'exceljs' },
        resolve: { loadScript: loadScriptResolver },
    },
    {
        path: 'login',
        title: SharedTranslateKeys.Login,
        loadComponent: async () => (await import('./login/app.login.component')).AppLoginComponent,
    },
    {
        path: 'not-found',
        title: 'Not found',
        loadComponent: async () => (await import('./layout/not-found/not-found.component')).NotFoundComponent,
    },
    {
        path: 'privacy-policy',
        title: SharedTranslateKeys.PrivacyPolicy,
        loadComponent: async () =>
            (await import('./privacy/privacy-policy/privacy-policy.component')).PrivacyPolicyComponent,
    },
    {
        path: 'not-authorized',
        title: 'Not authorized',
        loadComponent: async () =>
            (await import('./layout/not-authorized/not-authorized.component')).NotAuthorizedComponent,
    },
    { path: '**', redirectTo: '/not-found' },
];
