export enum ModulePermissions {
    TSH_VIEW = 'TSH_VIEW',
    TSH_WORKTIME_VIEW = 'TSH_WORKTIME_VIEW',
    TSH_WORKTIME_CREATE = 'TSH_WORKTIME_CREATE',
    TSH_WORKTIME_EDIT = 'TSH_WORKTIME_EDIT',
    TSH_WORKTIME_DELETE = 'TSH_WORKTIME_DELETE',

    TSH_WORKLOG_VIEW = 'TSH_WORKLOG_VIEW',
    TSH_WORKLOG_CREATE = 'TSH_WORKLOG_CREATE',
    TSH_WORKLOG_EDIT = 'TSH_WORKLOG_EDIT',
    TSH_WORKLOG_DELETE = 'TSH_WORKLOG_DELETE',
    TSH_WORKLOG_MANAGE_ALL = 'TSH_WORKLOG_MANAGE_ALL',
    TSH_WORKLOG_MANAGE_SUBORDINATE = 'TSH_WORKLOG_MANAGE_SUBORDINATE',

    TSH_CLIENT_VIEW = 'TSH_CLIENT_VIEW',
    TSH_CLIENT_CREATE = 'TSH_CLIENT_CREATE',
    TSH_CLIENT_EDIT = 'TSH_CLIENT_EDIT',
    TSH_CLIENT_DELETE = 'TSH_CLIENT_DELETE',

    TSH_PROJECT_VIEW = 'TSH_PROJECT_VIEW',
    TSH_PROJECT_CREATE = 'TSH_PROJECT_CREATE',
    TSH_PROJECT_EDIT = 'TSH_PROJECT_EDIT',
    TSH_PROJECT_DELETE = 'TSH_PROJECT_DELETE',
    TSH_PROJECT_VIEW_MY = 'TSH_PROJECT_VIEW_MY',

    TSH_TASK_VIEW = 'TSH_TASK_VIEW',
    TSH_TASK_CREATE = 'TSH_TASK_CREATE',
    TSH_TASK_EDIT = 'TSH_TASK_EDIT',
    TSH_TASK_DELETE = 'TSH_TASK_DELETE',
    TSH_TASK_SHOW_FIX_WH = 'TSH_TASK_SHOW_FIX_WH',

    TSH_BREAK_VIEW = 'TSH_BREAK_VIEW',
    TSH_BREAK_CREATE = 'TSH_BREAK_CREATE',
    TSH_BREAK_EDIT = 'TSH_BREAK_EDIT',
    TSH_BREAK_DELETE = 'TSH_BREAK_DELETE',
    TSH_BREAK_MANAGE_ALL = 'TSH_BREAK_MANAGE_ALL',
    TSH_BREAK_MANAGE_SUBORDINATE = 'TSH_BREAK_MANAGE_SUBORDINATE',

    TSH_ABSENT_VIEW = 'TSH_ABSENT_VIEW',
    TSH_ABSENT_CREATE = 'TSH_ABSENT_CREATE',
    TSH_ABSENT_EDIT = 'TSH_ABSENT_EDIT',
    TSH_ABSENT_DELETE = 'TSH_ABSENT_DELETE',
    TSH_ABSENT_MANAGE_ALL = 'TSH_ABSENT_MANAGE_ALL',
    TSH_ABSENT_MANAGE_SUBORDINATE = 'TSH_ABSENT_MANAGE_SUBORDINATE',

    TSH_OVERTIME_VIEW = 'TSH_OVERTIME_VIEW',
    TSH_OVERTIME_DELETE = 'TSH_OVERTIME_DELETE',
    TSH_OVERTIME_MANAGE_ALL = 'TSH_OVERTIME_MANAGE_ALL',
    TSH_OVERTIME_MANAGE_SUBORDINATE = 'TSH_OVERTIME_MANAGE_SUBORDINATE',

    TSH_CONTRACT_VIEW = 'TSH_CONTRACT_VIEW',
    TSH_CONTRACT_CREATE = 'TSH_CONTRACT_CREATE',
    TSH_CONTRACT_EDIT = 'TSH_CONTRACT_EDIT',
    TSH_CONTRACT_DELETE = 'TSH_CONTRACT_DELETE',

    TSH_ENGAGEMENT_VIEW = 'TSH_ENGAGEMENT_VIEW',
    TSH_ENGAGEMENT_CREATE = 'TSH_ENGAGEMENT_CREATE',
    TSH_ENGAGEMENT_EDIT = 'TSH_ENGAGEMENT_EDIT',
    TSH_ENGAGEMENT_DELETE = 'TSH_ENGAGEMENT_DELETE',

    TSH_REPORT_EMPLOYEE_HOURS_VIEW = 'TSH_REPORT_EMPLOYEE_HOURS_VIEW',
    TSH_REPORT_EMPLOYEE_CLIENT_VIEW = 'TSH_REPORT_EMPLOYEE_CLIENT_VIEW',
    TSH_REPORT_PANTHEON_VIEW = 'TSH_REPORT_PANTHEON_VIEW',
    TSH_REPORT_INCOMPLETE_DAYS_VIEW = 'TSH_REPORT_INCOMPLETE_DAYS_VIEW',
    TSH_REPORT_BENCH_VIEW = 'TSH_REPORT_BENCH_VIEW',

    TSH_UNLCOK_VIEW = 'TSH_UNLCOK_VIEW',
    TSH_UNLOCK_MANAGE_SUBORDINATE = 'TSH_UNLOCK_MANAGE_SUBORDINATE',
    TSH_UNLOCK_VIEW_ALL = 'TSH_UNLOCK_VIEW_ALL',
    TSH_UNLOCK_MENAGE_ALL = 'TSH_UNLOCK_MENAGE_ALL',

    TSH_SETTINGS_EDIT = 'TSH_SETTINGS_EDIT',
    TSH_SETTINGS_CREATE = 'TSH_SETTINGS_CREATE',
    TSH_SETTINGS_VIEW = 'TSH_SETTINGS_VIEW',

    TSH_EMAIL_EDIT = 'TSH_EMAIL_EDIT',
    TSH_EMAIL_CREATE = 'TSH_EMAIL_CREATE',
    TSH_EMAIL_VIEW = 'TSH_EMAIL_VIEW',

    // Permission prefixes
    TSH_WORKTIME = 'TSH_WORKTIME',
    TSH_CLIENT = 'TSH_CLIENT',
    TSH_PROJECT = 'TSH_PROJECT',
    TSH_TASK = 'TSH_TASK',
    TSH_BREAK = 'TSH_BREAK',
    TSH_ABSENT = 'TSH_ABSENT',
    TSH_OVERTIME = 'TSH_OVERTIME',
    TSH_WORKLOG = 'TSH_WORKLOG',
    TSH_CONTRACT = 'TSH_CONTRACT',
    TSH_ENGAGEMENT = 'TSH_ENGAGEMENT',
    TSH_REPORT = 'TSH_REPORT',
    TSH_UNLOCK = 'TSH_UNLOCK',
    TSH_EMAIL = 'TSH_EMAIL',
}
