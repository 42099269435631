import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptors,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    APP_INITIALIZER,
    importProvidersFrom,
    LOCALE_ID,
    makeEnvironmentProviders,
    provideZoneChangeDetection,
    type ApplicationConfig,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    provideRouter,
    TitleStrategy,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withRouterConfig,
} from '@angular/router';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MsalService,
    type MsalGuardConfiguration,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalInterceptor,
    MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    InteractionType,
    LogLevel,
    PublicClientApplication,
    type IPublicClientApplication,
} from '@azure/msal-browser';

import { HttpLoaderFactory, LocaleService, provideFeatureFlags, provideGtm, VALIDATION_ERRORS_TOKEN } from '@fiyu/api';
import {
    CacheInterceptor,
    CacheRepository,
    CustomTitleStrategy,
    EnvironmentService,
    ErrorInterceptor,
    LogInterceptor,
    OrganizationInterceptor,
    provideCoreModuleServices,
} from '@fiyu/core';
import { provideDocumentsModuleProviders } from '@fiyu/documents';
import { provideNotificationsModuleProviders } from '@fiyu/notifications';
import { provideOrganizationsModuleProviders } from '@fiyu/organizations';
import { providePlatformModuleProviders } from '@fiyu/platform';
import { provideUsersModuleProviders } from '@fiyu/users';
import { STATE_CONFIG_TOKEN } from '@fiyu/api';
import { provideTimesheetModuleServices } from '@getihub/timesheet';
import { TranslateLoader, TranslateModule, TranslateService, type TranslateModuleConfig } from '@ngx-translate/core';
import { provideClarity } from 'ngx-clarity';
import { provideNgcCookieConsent, type NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { firstValueFrom } from 'rxjs';
import { AppRoutes } from './app/app.routes';
import { validationErrors } from './app/module-validation-errors';
import { provideWorkspaceModuleProviders } from './app/workspace/module-providers';
import { environment } from './environments/environment';

export function appInitializerFactory(
    translateService: TranslateService,
    environmentService: EnvironmentService,
    localeService: LocaleService,
) {
    return async () => {
        Object.assign(environmentService, environment);
        environmentService.environment = environment.name;
        environmentService.readyObservable.next(true);
        environmentService.readyObservable.complete();
        const lang = localeService.locale;
        translateService.setDefaultLang(lang);
        await firstValueFrom(translateService.use(lang));
    };
}

function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(`[${logLevel}]: ${message}`);
}

function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.azureAuthClientId,
            authority: `https://login.microsoftonline.com/${environment.azureAuthTenantId}/`,
            redirectUri: environment.appHostUrl,
            postLogoutRedirectUri: `${environment.appHostUrl}/login`,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    });
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['openid'],
        },
        // loginFailedRoute: '/login-failed'
    };
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

const translateModuleConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
    },
    useDefaultLang: true,
};

const cookieConsentConfig: NgcCookieConsentConfig = {
    enabled: environment.production,
    autoOpen: environment.production,
    revokable: environment.production,
    // mobileForceFloat: true,
    cookie: {
        domain: new URL(environment.appHostUrl)?.host ?? 'localhost', // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    position: 'bottom',
    theme: 'classic', // 'block', 'classic', 'edgeless'
    palette: {
        popup: {
            background: '#000000',
            text: '#ffffff',
            link: '#ffffff',
        },
        button: {
            background: '#f1d600',
            text: '#000000',
            border: 'transparent',
        },
    },
    type: 'opt-out',
    content: {
        allow: 'Allow cookies',
        deny: 'Refuse cookies',
        dismiss: 'Got it!',
        header: 'Cookies used on the website!',
        link: 'Learn more',
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        policy: 'Cookie Policy',
        href: `${environment.appHostUrl}/privacy-policy`,
    },
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideCoreModuleServices(),
        provideWorkspaceModuleProviders(),
        providePlatformModuleProviders(),
        provideDocumentsModuleProviders(),
        provideNotificationsModuleProviders(),
        provideOrganizationsModuleProviders(),
        provideUsersModuleProviders(),
        provideTimesheetModuleServices(),
        importProvidersFrom(TranslateModule.forRoot(translateModuleConfig)),
        provideRouter(
            AppRoutes,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withEnabledBlockingInitialNavigation(),
            withComponentInputBinding(),
            withRouterConfig({ paramsInheritanceStrategy: 'always', resolveNavigationPromiseOnError: true }),
            //  withViewTransitions({ skipInitialTransition: false }),
            // withDebugTracing()
        ),
        provideAnimationsAsync(),
        provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
        provideHttpClient(
            withInterceptors([OrganizationInterceptor, LogInterceptor, ErrorInterceptor, CacheInterceptor]),
            withInterceptorsFromDi(),
        ),
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: (localeService: LocaleService) => localeService.locale,
        },
        {
            provide: STATE_CONFIG_TOKEN,
            useValue: () => {
                return {
                    storeName: 'getihub-store',
                    persistState: true,
                };
            },
        },
        makeEnvironmentProviders([
            {
                provide: APP_INITIALIZER,
                useFactory: appInitializerFactory,
                deps: [TranslateService, EnvironmentService, LocaleService],
                multi: true,
            },
        ]),
        provideFeatureFlags(),
        CacheRepository,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: TitleStrategy, useClass: CustomTitleStrategy },
        provideNgcCookieConsent(cookieConsentConfig),
        provideGtm({
            enabled: false,
            id: environment.googleTagManagerTrackingId,
        }),
        provideClarity({
            enabled: false,
            projectId: environment.clarityProjectId,
        }),
        { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
};
