import { FiyuRoutes, PermissionsMatchGuard } from '@fiyu/core';
import { MODULE_METADATA_TOKEN, Operations, VALIDATION_ERRORS_TOKEN } from '@fiyu/api';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { validationErrors } from './module-validation-errors';

export const TimesheetRoutes: FiyuRoutes = [
    {
        path: '',
        loadComponent: async () =>
            (await import('./timesheet-layout/timesheet-layout.component')).TimesheetLayoutComponent,
        providers: [
            { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
            { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
        ],
        children: [
            {
                path: 'calendar',
                loadComponent: async () => (await import('./calendar/calendar.component')).CalendarComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_VIEW],
            },
            {
                path: 'calendar/previous',
                loadComponent: async () =>
                    (await import('./copy-from-previous-day/copy-from-previous-day/copy-from-previous-day.component'))
                        .CopyFromPreviousDayComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [
                    ModulePermissions.TSH_WORKLOG_CREATE,
                    ModulePermissions.TSH_BREAK_CREATE,
                    ModulePermissions.TSH_ABSENT_CREATE,
                ],
                operation: Operations.AND,
            },
            {
                path: 'task/:id/edit',
                loadComponent: async () => (await import('./task/task-form/task-form.component')).TaskFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_TASK_EDIT],
            },
            {
                path: 'task',
                loadComponent: async () => (await import('./task/task-form/task-form.component')).TaskFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_TASK_CREATE],
            },
            {
                path: 'tasks',
                loadComponent: async () => (await import('./task/task-list/task-list.component')).TaskListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_TASK_VIEW],
            },
            {
                path: 'client/:id/edit',
                loadComponent: async () =>
                    (await import('./client/client-form/client-form.component')).ClientFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CLIENT_EDIT],
            },
            {
                path: 'client',
                loadComponent: async () =>
                    (await import('./client/client-form/client-form.component')).ClientFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CLIENT_CREATE],
            },
            {
                path: 'clients',
                loadComponent: async () =>
                    (await import('./client/client-list/client-list.component')).ClientListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CLIENT_VIEW],
            },
            {
                path: 'project/:id/edit',
                loadComponent: async () =>
                    (await import('./project/project-form/project-form.component')).ProjectFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_PROJECT_EDIT],
            },
            {
                path: 'project',
                loadComponent: async () =>
                    (await import('./project/project-form/project-form.component')).ProjectFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_PROJECT_CREATE],
            },
            {
                path: 'projects',
                loadComponent: async () =>
                    (await import('./project/project-list/project-list.component')).ProjectListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_PROJECT_VIEW],
            },
            {
                path: 'contract',
                loadComponent: async () =>
                    (await import('./contract/contract-form/contract-form.component')).ContractFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CONTRACT_CREATE],
            },
            {
                path: 'contract/:id/edit',
                loadComponent: async () =>
                    (await import('./contract/contract-form/contract-form.component')).ContractFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CONTRACT_EDIT],
            },
            {
                path: 'contracts',
                loadComponent: async () =>
                    (await import('./contract/contract-list/contract-list.component')).ContractListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CONTRACT_VIEW],
            },
            {
                path: 'overtimes',
                loadComponent: async () =>
                    (await import('./overtime/overtime-list/overtime-list.component')).OverTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'passive-overtimes',
                loadComponent: async () =>
                    (await import('./passive-overtime/passive-overtime-list/passive-overtime-list.component'))
                        .PassiveOvertimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'landing',
                loadComponent: async () => (await import('./calendar/calendar.component')).CalendarComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_VIEW],
            },
            {
                path: 'worktime',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/worktime-form.component')).WorktimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_WORKLOG_CREATE],
            },
            {
                path: 'worktime/:id/edit',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/worktime-form.component')).WorktimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_WORKLOG_EDIT],
            },
            {
                path: 'worktimes',
                loadComponent: async () =>
                    (await import('./worktime/worktime-list/worktime-list.component')).WorktimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_WORKLOG_VIEW],
            },
            {
                path: 'admin/worktime',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/worktime-form.component')).WorktimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
                    ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/worktime/:id/edit',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/worktime-form.component')).WorktimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
                    ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/worktimes',
                loadComponent: async () =>
                    (await import('./worktime/worktime-list/worktime-list.component')).WorktimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
                    ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'absent-time',
                loadComponent: async () =>
                    (await import('./absent-time/absent-time-form/absent-time-form.component')).AbsentTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_ABSENT_CREATE],
            },
            {
                path: 'absent-time/:id/edit',
                loadComponent: async () =>
                    (await import('./absent-time/absent-time-form/absent-time-form.component')).AbsentTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_ABSENT_EDIT],
            },
            {
                path: 'absent-times',
                loadComponent: async () =>
                    (await import('./absent-time/absent-time-list/absent-time-list.component')).AbsentTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_ABSENT_VIEW],
            },
            {
                path: 'admin/absent-time',
                loadComponent: async () =>
                    (await import('./absent-time/absent-time-form/absent-time-form.component')).AbsentTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_ABSENT_MANAGE_ALL,
                    ModulePermissions.TSH_ABSENT_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/absent-time/:id/edit',
                loadComponent: async () =>
                    (await import('./absent-time/absent-time-form/absent-time-form.component')).AbsentTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_ABSENT_MANAGE_ALL,
                    ModulePermissions.TSH_ABSENT_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/absent-times',
                loadComponent: async () =>
                    (await import('./absent-time/absent-time-list/absent-time-list.component')).AbsentTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_ABSENT_MANAGE_ALL,
                    ModulePermissions.TSH_ABSENT_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'break-time',
                loadComponent: async () =>
                    (await import('./break-time/break-time-form/break-time-form.component')).BreakTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_BREAK_CREATE],
            },
            {
                path: 'break-time/:id/edit',
                loadComponent: async () =>
                    (await import('./break-time/break-time-form/break-time-form.component')).BreakTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_BREAK_EDIT],
            },
            {
                path: 'break-times',
                loadComponent: async () =>
                    (await import('./break-time/break-time-list/break-time-list.component')).BreakTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_BREAK_VIEW],
            },
            {
                path: 'admin/break-time',
                loadComponent: async () =>
                    (await import('./break-time/break-time-form/break-time-form.component')).BreakTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_BREAK_MANAGE_ALL,
                    ModulePermissions.TSH_BREAK_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/break-time/:id/edit',
                loadComponent: async () =>
                    (await import('./break-time/break-time-form/break-time-form.component')).BreakTimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_BREAK_MANAGE_ALL,
                    ModulePermissions.TSH_BREAK_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/break-times',
                loadComponent: async () =>
                    (await import('./break-time/break-time-list/break-time-list.component')).BreakTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_BREAK_MANAGE_ALL,
                    ModulePermissions.TSH_BREAK_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'overtimes',
                loadComponent: async () =>
                    (await import('./overtime/overtime-list/overtime-list.component')).OverTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'admin/overtimes',
                data: { admin: true },
                loadComponent: async () =>
                    (await import('./overtime/overtime-list/overtime-list.component')).OverTimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'overtime/:id/edit',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/overtime-form.component')).OvertimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_WORKTIME_EDIT],
            },
            {
                path: 'admin/overtime/:id/edit',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/overtime-form.component')).OvertimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
                    ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'passive-overtime',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/passive-overtime-form.component'))
                        .PassiveOvertimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_WORKLOG_CREATE],
            },
            {
                path: 'passive-overtimes',
                loadComponent: async () =>
                    (await import('./passive-overtime/passive-overtime-list/passive-overtime-list.component'))
                        .PassiveOvertimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'admin/passive-overtimes',
                data: { admin: true },
                loadComponent: async () =>
                    (await import('./passive-overtime/passive-overtime-list/passive-overtime-list.component'))
                        .PassiveOvertimeListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'passive-overtime/:id/edit',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/passive-overtime-form.component'))
                        .PassiveOvertimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'admin/passive-overtime',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/passive-overtime-form.component'))
                        .PassiveOvertimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_WORKLOG_CREATE],
            },
            {
                path: 'admin/passive-overtime/:id/edit',
                loadComponent: async () =>
                    (await import('./worktime/worktime-form/passive-overtime-form.component'))
                        .PassiveOvertimeFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                data: { admin: true },
                requiredPermissions: [
                    ModulePermissions.TSH_WORKLOG_MANAGE_ALL,
                    ModulePermissions.TSH_WORKLOG_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'passive-overtime/interval',
                loadComponent: async () =>
                    (await import('./passive-overtime/passive-overtime-form/passive-overtime-interval-form.component'))
                        .PassiveOvertimeIntervalFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_OVERTIME_VIEW],
            },
            {
                path: 'admin/passive-overtime/interval',
                data: { admin: true },
                loadComponent: async () =>
                    (await import('./passive-overtime/passive-overtime-form/passive-overtime-interval-form.component'))
                        .PassiveOvertimeIntervalFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [
                    ModulePermissions.TSH_OVERTIME_MANAGE_ALL,
                    ModulePermissions.TSH_OVERTIME_MANAGE_SUBORDINATE,
                ],
            },
            {
                path: 'admin/incomplete-days',
                loadComponent: async () =>
                    (await import('./incomplete-days/incomplete-days.component')).IncompleteDaysComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_REPORT_INCOMPLETE_DAYS_VIEW],
            },
            {
                path: 'unlock-requests',
                loadComponent: async () =>
                    (await import('./unlock-request/unlock-request-list/unlock-request-list.component'))
                        .UnlockRequestListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [
                    ModulePermissions.TSH_UNLOCK_MANAGE_SUBORDINATE,
                    ModulePermissions.TSH_UNLOCK_MENAGE_ALL,
                    ModulePermissions.TSH_UNLOCK_VIEW_ALL,
                ],
                operation: Operations.OR
            },
            {
                path: 'contracts',
                loadComponent: async () =>
                    (await import('./contract/contract-list/contract-list.component')).ContractListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CONTRACT_VIEW],
            },
            {
                path: 'contract',
                loadComponent: async () =>
                    (await import('./contract/contract-form/contract-form.component')).ContractFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CONTRACT_CREATE],
            },
            {
                path: 'contract/:id/edit',
                loadComponent: async () =>
                    (await import('./contract/contract-form/contract-form.component')).ContractFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_CONTRACT_EDIT],
            },
            {
                path: 'engagement/:id/edit',
                loadComponent: async () =>
                    (await import('./engagement/engagement-form/engagement-form.component')).EngagementFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_ENGAGEMENT_EDIT],
            },
            {
                path: 'engagements',
                loadComponent: async () =>
                    (await import('./engagement/engagement-list/engagement-list.component')).EngagementListComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_ENGAGEMENT_VIEW],
            },
            {
                path: 'engagement',
                loadComponent: async () =>
                    (await import('./engagement/engagement-form/engagement-form.component')).EngagementFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_ENGAGEMENT_CREATE],
            },
            {
                path: 'reports/employee-hours',
                loadComponent: async () =>
                    (await import('./reports/employee-hours-report/employee-hours-report.component'))
                        .EmployeeHoursReportComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_REPORT_EMPLOYEE_HOURS_VIEW],
            },
            {
                path: 'reports/employee-client',
                loadComponent: async () =>
                    (await import('./reports/employee-client-report/employee-client-report.component'))
                        .EmployeeClientReportComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_REPORT_EMPLOYEE_CLIENT_VIEW],
            },
            {
                path: 'reports/employee-task',
                loadComponent: async () =>
                    (await import('./reports/employee-task-report/employee-task-report.component'))
                        .EmployeeTaskReportComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_REPORT_EMPLOYEE_CLIENT_VIEW],
            },
            {
                path: 'reports/pantheon',
                loadComponent: async () =>
                    (await import('./reports/pantheon-report/pantheon-report.component')).PantheonReportComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_REPORT_PANTHEON_VIEW],
            },
            {
                path: 'reports/bench',
                loadComponent: async () =>
                    (await import('./reports/bench-report/bench-report.component')).BenchReportComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_REPORT_BENCH_VIEW],
            },
            {
                path: '',
                redirectTo: 'landing',
                pathMatch: 'full',
            },

            {
                path: 'email',
                loadComponent: async () => (await import('./email/email-form/email-form.component')).EmailFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_EMAIL_CREATE, ModulePermissions.TSH_EMAIL_EDIT],
            },

            {
                path: 'settings',
                loadComponent: async () =>
                    (await import('./settings/timesheet-settings-form/timesheet-settings-form.component'))
                        .TimesheetSettingsFormComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
                requiredPermissions: [ModulePermissions.TSH_SETTINGS_CREATE, ModulePermissions.TSH_SETTINGS_EDIT],
                operation: Operations.OR
            },
            {
                path: 'about',
                loadComponent: async () => (await import('./about/about-landing.component')).AboutLandingComponent,
                canMatch: [PermissionsMatchGuard],
                requiredModule: 'tsh',
            },
        ],
    },
];
