import { AbstractEntity, Entity, EnumUtility, FormEntity } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';

export enum AbsentStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}
export enum BreakTimeInsertEnum {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export enum CountryEnum {
  HR = 'Croatia',
  RS = 'Serbia',
  BIH = 'Bosnia and Herzegovina'
}

@Entity({
  isAdmin: true,
  singularName: 'Setting',
  pluralName: 'Settings',
  contentType: 'application/module.settings.v1+json',
  permissionPrefix: ModulePermissions.TSH_SETTINGS_VIEW,
  formBlockTitles: {
    1: 'General',
    2: 'Holidays',
  },
})
export class TimesheetModuleSettings extends AbstractEntity {
  @FormEntity({
    label: 'Number of working hours in a day',
    groupNumber: 1,
    initialValue: 8,
    required: true,
    formType: 'number',
    fieldOptions: {
      min: 1,
      max: 24,
   }})
  WORK_DAY_HOURS!: number;

  @FormEntity({
    label: 'Number of absent days in year (subcontractor)',
    groupNumber: 1,
    initialValue: 35,
    required: true,
    formType: 'number',
  })
  SUBCON_EMPLOYEE_ABSENT_TIME_LIMIT!: number;
  @FormEntity({
    label: 'Duration of break time that employee has in one working day',
    groupNumber: 1,
    initialValue: 30,
    required: true,
    formType: 'number'
  })
  BREAK_TIME_DURATION!: number;

  @FormEntity({
    label:
      'Minimal work time for an work time entry before break time will be automatically inserted',
    groupNumber: 1,
    initialValue: 6,
    required: true,
    formType: 'number',
  })
  MINIMAL_HOURS_FOR_BREAK_TIME!: number;
  @FormEntity({
    label: 'Number of business days before locking mechanism isn’t applied',
    groupNumber: 1,
    initialValue: 3,
    required: true,
    formType: 'number'
  })
  DAYS_BEFORE_LOCKED!: number;

  @FormEntity({
    label:
      'Number of business days that unlocked day can be edited, and are in list of unlocked days',
    groupNumber: 1,
    initialValue: 3,
    required: true,
    formType: 'number',
  })
  UNLOCKED_DAY_GRACE_PERIOD!: number;

  @FormEntity({
    label:
      'Choose status of created absent time (if set to PENDING absent time needs to be approved by admin)',
    groupNumber: 1,
    formType: 'dropdown',
    lookupOptions: EnumUtility.mapEnumToSelectItems(AbsentStatusEnum),
    initialValue: AbsentStatusEnum.APPROVED,
    required: true,
  })
  ABSENT_TIME_STATUS!: string;

  @FormEntity({
    label: 'Enable Manual Break Time Insert',
    groupNumber: 1,
    formType: 'dropdown',
    lookupOptions: EnumUtility.mapEnumToSelectItems(BreakTimeInsertEnum),
    initialValue: BreakTimeInsertEnum.DISABLE,
    required: true,
  })
  BREAK_TIME_MANUAL_INSERT!: string;


  @FormEntity({
    label: 'Select non working days',
    groupNumber: 2,
    required: false,
  })
  NON_WORKING_DAYS!: [{code:'HR', dates: string}, {code: 'RS', dates: string}];

  @FormEntity({
    label: 'Country',
    groupNumber: 3,
    required: true,
    formType: 'dropdown',
    lookupOptions: EnumUtility.mapEnumToSelectItems(CountryEnum),
    initialValue: 'HR',

  })
  COUNTRY!: string;
}
